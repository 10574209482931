import * as dompack from 'dompack';
import * as mso from '@mod-mso/webdesigns/mso/mso';

import './rosahoeve.scss';
import 'sweetalert2/dist/sweetalert2.min.css';

// pages
import './pages/order/order';
import './pages/webshop/webshop';
// import './orderform/orderform.es';

import * as wrdauth from '@mod-wrd/js/auth';

dompack.onDomReady(() => {
  new mso.MSOSite();
});
