import './webshop.scss';

import * as whintegration from '@mod-system/js/wh/integration';
import * as dompack from 'dompack';
import * as util from '../../shared/utilities';
import * as rpc from '../../shared/js/services.rpc.json';
import $ from 'jquery';
window.jQuery = $;
require('bootstrap-input-spinner');

window.localForage = require('localforage'); // for testing purposes

let $numInputs = [], elProducts;
let firstItemAdded = false;
let onInitNoProducts = false;
let isMobile = false;
// let lastSearchValue = '';
let basketContents;
let basket;

class Basket {
  constructor() {
    this.products = [];
    this.initializeLocalStorage();
    this.sync();
  }

  async initializeLocalStorage() {
    let products = await localForage.getItem('basket');
    if (!products || products.length == 0) {
      onInitNoProducts = true;
      return; // no products, we're done
    }

    // for (let product of products) {
    //   let product_exists = await rpc.ProductExists(product.id);

    //   if (product_exists) {
    //     this.products.push(product);
    //     $('.webshop__numinputcontainer > .webshop__numinput[data-product-id="' + product.id + '"]').val(product.num);
    //   } else {
    //     console.error("could not find product #" + product.id);
    //   }
    // }
    for (let product of products) {
      this.products.push(product);
      $('.webshop__numinputcontainer > .webshop__numinput[data-product-id="' + product.id + '"]').val(product.num);
    }
    this.sync();

    // if (!isMobile)
    //   toggleBasketWindowVisibility(null, true);
  }

  updateProduct(productId, num) {
    const productDetails = whintegration.config.obj.products.find(product => product.id == productId);
    if (!productDetails) {
      throw `no product ${productId}`;
    }

    if (num < 1) {
      // remove from array
      this.products = this.products.filter(product => {
        return product.id != productId;
      })
      this.sync();
      return;
    }

    // is it already in our products list?
    let productIdx = this.products.findIndex(product => product.id == productId);
    if (productIdx == -1) { // it's not, add it
      this.products.push(productDetails);
      productIdx = this.products.length - 1;
    }

    this.products[productIdx].num = num;
    this.sync();
  }

  async removeProduct(productId = 0) {
    const product = this.products.find(product => product.id == productId);
    if (!product)
      throw `no product ${productId}`;

    const confirm = await util.confirm(
      'Product verwijderen',
      `Weet u zeker dat u het product "${product.title}" uit uw bestelling wilt verwijderen?`,
    );

    if (confirm.isConfirmed) {
      // remove from input (if it's on this page)
      $(`.webshop__numinputcontainer > .webshop__numinput[data-product-id=${productId}]`).val('');

      // remove from array
      this.products = this.products.filter(product => {
        return product.id != productId;
      })

      this.sync();
    }
  }

  sync() {
    this.updateBasketContents();
    localForage.setItem('basket', this.products);
    $('.js-num-products').text(this.products.length < 1 ? '' : this.products.length);
  }

  async updateBasketContents() {
    let totalPrice = 0.0, totalDeposit = 0.0;
    let rows = '';
    for (const product of this.products) {
      let price = product.num * product.price_cents / 100;
      let deposit = product.num * product.deposit_cents / 100;

      let priceFormatted = util.formatMoney(price);
      rows = rows + `<li class="basket__productrow">
                       <span class="basket__listitem basket__listitem--num">${product.num}x</span>
                       <span class="basket__listitem basket__listitem--title">
                         <span>${product.title}</span>
                       </span>
                       <span class="basket__listitem basket__listitem--price">€ ${priceFormatted}</span>
                       <a href="#" class="js-remove-from-basket" data-id="${product.id}" data-title="${product.title}">
                         <span class="fa fa-trash"></span>
                       </a>
                     </li>`;

      totalDeposit += deposit;
      totalPrice += price + deposit;
    }

    $('.basket__productrow').remove();
    $('.basket__list').prepend(rows);
    $('.js-total-deposit').text('€ ' + util.formatMoney(totalDeposit));
    // rpc call to calculate discount
    let discount = await rpc.GetDiscount(this.products);
    $('.js-discount-price').text('€ ' + util.formatMoney(discount));
    $('.js-total-price').text('€ ' + (util.formatMoney(totalPrice - discount)));

    $('.basket__productrow .js-remove-from-basket').click((evt) => {
      evt.preventDefault();
      const $link = $(evt.target).closest('.js-remove-from-basket');
      this.removeProduct(parseInt($link.data('id'), 10));
    });
  }

  async empty(evt) {
    if (evt)
      evt.preventDefault();

    const confirm = await util.confirm(
      'Bestelling verwijderen',
      'Weet u zeker dat u de bestelling wilt verwijderen? Dit verwijdert ook alle aantallen in het bestelformulier.',
    );

    if (confirm.isConfirmed) {
      // localForage.removeItem('basket');
      $numInputs.val('');
      this.products = [];
      this.sync();
      // this.updateBasketContents([]);
    }
  }

}

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-webshop'))
    return;

  $("input[type='number']").inputSpinner();

  // check hash for product id and scroll to product if id exists
  // if (location.hash !== '') {
  //   let hashProductId = parseInt(location.hash.substring(1), 10);
  //   if (!isNaN(hashProductId)) {
  //     tryScrollToProduct(hashProductId);
  //   }
  // }

  basket = new Basket();
  window.basket = basket;

  // cache
  isMobile = $(window).width() < 768;
  $numInputs = $('.webshop__numinputcontainer > .webshop__numinput');
  elProducts = dompack.qSA('.webshop__productcard');

  // events
  $('.js-show-basket-window').click(evt => toggleBasketWindowVisibility(evt, true));
  $('.js-close-basket-window').click(evt => toggleBasketWindowVisibility(evt, false));
  $('.js-empty-basket').click(evt => basket.empty(evt));
  $numInputs.bind('input', evt => onNumInputChange(evt));
  $('.js-order').click(tryOrderAction);
  $('.js-show-productinfo').on('click', function(evt) {
    evt.preventDefault();
    $(this).closest('.webshop__productcardinfo').find('.js-productinfo').addClass('show');
  });
  $('.js-close-productinfo').on('click', function(evt) {
    evt.preventDefault();
    $(this).closest('.webshop__productcardinfo').find('.js-productinfo').removeClass('show');
  });

  if (isMobile) {
    dompack.qSA('.js-product').forEach(node => {
      node.addEventListener('click', onProductClick);
    });
  }

  // if (dompack.debugflags['debug']) toggleBasketWindowVisibility(null, true);
  // if (dompack.debugflags['debug']) $('.js-request-empty-basket').trigger('click');
});

function onProductClick(evt) {
  evt.preventDefault();

  let id = evt.target.getAttribute('data-id');
  let $targetRow = $(`.productstable__moreinforow[data-id="${id}"]`);

  $targetRow.toggleClass('show');
}

// function scrollToElementClick(evt) {
//   evt.preventDefault();

//   let $scrollTo = $('#' + $(this).data('scrollto'));
//   if ($scrollTo.length == 0)
//     return;

//   let $scrollContainer = $('.mainblock--content');

//   let scrollToPos = $scrollTo.offset().top - $scrollContainer.offset().top + $scrollContainer.scrollTop() - parseInt($scrollTo.css('margin-top'));
//   $scrollContainer.animate({ scrollTop: scrollToPos - 10 /* little extra margin */});
// }

// updates product information from the database
// FIXME: this code assumed we have all products on the page, which we don't have anymore
// function syncLocalStorageProductsInfo(products) {
//   for (let product of products) {
//     let productDetails = whintegration.config.obj.products.find(x => x.wrd_id == product.id);
//     if (productDetails) {
//       product.id = productDetails.id;
//       product.title = productDetails.title;
//       product.price_cents = productDetails.price_cents;
//       product.deposit_cents = productDetails.deposit_cents;
//     }
//   }

//   localForage.setItem('basket', products);
// }

function toggleBasketWindowVisibility(evt, force) {
  if (evt) {
    evt.preventDefault();
  }

  let $basketWindow = $('.basket__window');
  const showBasket = typeof force === 'undefined' ? !$('.basket__window').hasClass('show') : force;
  $('.basket__window').toggleClass('show', showBasket);
  $('html').toggleClass('show-basket', showBasket);
}

function onNumInputChange(evt) {
  if (evt.target.value === '0') {
    evt.target.value = '';
  }

  const id = parseInt(evt.target.getAttribute('data-product-id'), 10) || 0;
  const num = parseInt(evt.target.value) || 0;
  const title = evt.target.getAttribute('data-product-title');

  basket.updateProduct(id, num);
}

async function setLocalStorageItemsByInputs() {
  let products = await util.setLocalStorageItemsByInputs($numInputs);

  // first time we added something?
  if (onInitNoProducts && products.length > 0 && !firstItemAdded) {
    firstItemAdded = true;
    toggleBasketWindowVisibility(null, true);
  }

  updateBasketContents(products);
}

function tryScrollToProduct(productId = 0) {
  if (productId == 0)
    return;

  let scrollContainer = $('.mainblock--content');
  let scrollTo = $(`.productstable__productrow[data-id="${productId}"]`);
  if (scrollTo.length == 0)
    return;

  scrollContainer.animate({
    scrollTop: scrollTo.offset().top - scrollContainer.offset().top + scrollContainer.scrollTop()
  });
}

async function tryOrderAction(evt) {
  evt.preventDefault();

  var linkTo = $(this).attr('href');

  let products = await localForage.getItem('basket');
  if (!products || products.length == 0) {
    util.alert('Geen producten', 'Selecteert u eerst a.u.b. ten minste 1 product.');
    return;
  }

  location.href = linkTo;
}
