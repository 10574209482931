/* global $, flatpickr */
import './order.scss';

import * as whintegration from '@mod-system/js/wh/integration';
import * as dompack from 'dompack';
import { RPCFormBase, registerHandler } from '@mod-publisher/js/forms';
import * as wrdauth from '@mod-wrd/js/auth';
import * as util from '../../shared/utilities.es';
import * as rpc from '../../shared/js/services.rpc.json';

// vendor
import $ from 'jquery';
require( 'datatables.net' )( window, $ );
// import flatpickr from "flatpickr";
window.localForage = require('localforage'); // for testing purposes

// cached
let products = [];
let $numInputs = [];

class Form extends RPCFormBase {
  constructor(node) {
    super(node);
    this.node = node;

    window.addEventListener("wh:wrdauth-loginfailed", evt => {
      evt.preventDefault();
      console.error(evt);
      util.alert('Fout bij inloggen', 'Het ingevulde e-mailadres en/of wachtwoord is incorrect');
    });

    dompack.onDomReady(() => {
      this.setupDatepicker();
    });

    // try to get data from local storage, if any
    // this.fillByLocalStorage();

    this.elements.send_copy.checked = true;

    if (dompack.debugflags['debug']) {
      this.elements.conditions.checked = true;
      // window.setTimeout(() => { $('.wh-wrdauth__loginbutton').get(0).click(); }, 500);
    }
  }

  // async fillByLocalStorage() {
  //   let userData = await localForage.getItem('userdata');
  //   if (userData && userData.length > 0) {
  //     for (const userCell of userData) {
  //       let $el = $('#' + userCell.id);
  //       if (!$el.length)
  //         continue;

  //       $el.val(userCell.value);
  //     }
  //   } else if (dompack.debugflags['debug']) {
  //     this.elements.firstname.value = 'Wouter';
  //     this.elements.lastname.value = 'Hendriks';
  //     this.elements.email.value = 'testuser@beta.webhare.net';
  //     this.elements.phone.value = '06-12345678';
  //     this.elements.address_street.value = 'Straat';
  //     this.elements.address_nr_detail.value = '1';
  //     this.elements.address_zip.value = 'Postcode';
  //     this.elements.address_city.value = 'Plaatsnaam';
  //   }
  // }

  async setupDatepicker() {
    let businessdatesettings = await rpc.GetBusinessDays();
    let ithis = this;

    let pickupdate = flatpickr('#order-pickupdate', {
      locale: "nl",
      altInput: true,
      allowInput: true,
      altFormat: "d-m-Y",
      dateFormat: "Y-m-d",
      weekNumbers: true,
      minDate: new Date().fp_incr(3), // 3 days from now
      "locale": {
        "firstDayOfWeek": 1 // start week on Monday
      },
      enable: [
        function(date) {
          for (let element in businessdatesettings.businessdays) {
            let open = new Date(businessdatesettings.businessdays[element].open);
            open.setTime(open.getTime() + (open.getTimezoneOffset() * 60 * 1000));

            let close = new Date(businessdatesettings.businessdays[element].close);
            close.setTime(close.getTime() + (close.getTimezoneOffset() * 60 * 1000));

            if (parseInt(businessdatesettings.businessdays[element].month_end) < parseInt(businessdatesettings.businessdays[element].month_begin)) {
              
              if ((date.getMonth() >= (parseInt(businessdatesettings.businessdays[element].month_begin) - 1))) {

                if ((Boolean(businessdatesettings.businessdays[element].weekdays.find(x => (parseInt(x.weekday) - 1) == (date.getDay() - 1))))) {
                  dompack.qS(".pickuptime").innerHTML = `Standaard afhaaltijden: ${open.toLocaleTimeString('nl-NL').slice(0,5)} - ${close.toLocaleTimeString('nl-NL').slice(0,5)}`;
                  return true;
                }
              } if ((date.getMonth() <= (parseInt(businessdatesettings.businessdays[element].month_end) - 1))) {
                if ((Boolean(businessdatesettings.businessdays[element].weekdays.find(x => (parseInt(x.weekday) - 1) == (date.getDay() - 1))))) {
                  dompack.qS(".pickuptime").innerHTML = `Standaard afhaaltijden: ${open.toLocaleTimeString('nl-NL').slice(0,5)} - ${close.toLocaleTimeString('nl-NL').slice(0,5)}`;
                  return true;
                }
              }
            } else if ((date.getMonth() >= (parseInt(businessdatesettings.businessdays[element].month_begin) - 1))) {
              if ((date.getMonth() <= (parseInt(businessdatesettings.businessdays[element].month_end) - 1))) {
                if ((Boolean(businessdatesettings.businessdays[element].weekdays.find(x => (parseInt(x.weekday) - 1) == (date.getDay() - 1))))) {

                  // Because "afhaaltijden" added to .pickuptime is one displayed one month ahead for some reason 
                  if ((date.getMonth() >= (parseInt(businessdatesettings.businessdays[element].month_begin)))) {
                    if ((date.getMonth() <= (parseInt(businessdatesettings.businessdays[element].month_end)))){
                      dompack.qS(".pickuptime").innerHTML = `Standaard afhaaltijden: ${open.toLocaleTimeString('nl-NL').slice(0,5)} - ${close.toLocaleTimeString('nl-NL').slice(0,5)}`;
                    }
                  }

                  return true;
                }
              }
            }
          }
        },
        ...this.checkExceptions( businessdatesettings.businessdays_exceptions),
      ],
      defaultDate: Date.now(),
    });
  }

  checkExceptions(businessdays_exceptions) {
    let exceptiondates = [];

    let exeption_times = "<br> Extra data en tijdstippen: ";
    
    for (let exception in businessdays_exceptions) {
      const businessdate = new Date(businessdays_exceptions[exception].open);
      businessdate.setTime(businessdate.getTime() + this.getTimeOffset(businessdate));
      
      const businessdateclose = new Date(businessdays_exceptions[exception].close);
      businessdateclose.setTime(businessdateclose.getTime() + this.getTimeOffset(businessdateclose));

      exceptiondates.push(`${businessdate.getFullYear()}-${(businessdate.getMonth() + 1)}-${businessdate.getDate()}`);
      exeption_times += `<br> ${businessdate.toLocaleDateString('nl-NL', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}: ${businessdate.toLocaleTimeString('nl-NL').slice(0,5)} - ${businessdateclose.toLocaleTimeString('nl-NL').slice(0,5)}`;

    }

    let pickuptimeelement = dompack.qS(".pickuptime_ex").innerHTML = exeption_times;

    return exceptiondates;
  }

  getTimeOffset(jsDate) {
    return jsDate.getTimezoneOffset() * 60 * 1000;
  }

  getFormExtraSubmitData() {
    return { products: getProductsFromInputs() };
  }

  onSubmitSuccess() {
    // let userData = [];
    // for (const el of this.elements) {
    //   if (el.id !== '') {
    //     let $el = $('#' + el.id);
    //     // only interested in text, e-mail and textarea
    //     if ($el.attr('type') !== 'text'
    //         && $el.attr('type') !== 'email'
    //         && $el.get(0).tagName !== 'TEXTAREA') {
    //       continue;
    //     }

    //     if ($el) {
    //       userData.push({ 'id': el.id
    //                     , 'value': $el.val()
    //                     });
    //     }
    //   }
    // }

    // localForage.setItem('userdata', userData);
  }
}

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-order')) {
    return;
  }

  registerHandler('rosahoeve:order', node => new Form(node));

  // setupOrderPickup();
  setupProductsTable();

  let $conditionsLabel = $('.wh-form__optionlabel[for="order-conditions"]');
  $conditionsLabel.html($conditionsLabel.html().replace('de voorwaarden', `<a href="${whintegration.config.obj.conditionslink}" target="_blank">de voorwaarden</a>`));

  let $toAccountLinkSpan = $('.wh-form__fieldgroup--richtext[data-wh-form-group-for="thankyoutext"] span');
  $toAccountLinkSpan.replaceWith(`<a href="${whintegration.config.site.pages.account}">${$toAccountLinkSpan.text()}</a>`);
});

// function setupOrderPickup() {
//   let $willPickUp = $('.order__pickup input[type="checkbox"]');
//   let $addressFields = $('.order__address input');

//   $addressFields.attr('required', 'required');

//   $willPickUp.change(() => {
//     let willPickUp = $willPickUp.is(":checked") === true;

//     $('.order__address').toggleClass('hide', willPickUp);

//     $addressFields.each(function() {
//       $(this).attr('required', willPickUp ? null : 'required');
//     });
//   });
// }

async function setupProductsTable() {
  try {
    products = await localForage.getItem('basket');
    if (!products || products.length == 0) {
      throw 'No items';
    }

    let rows = '';
    let totalPrice = 0.0;

    for (let product of products) {
      // get product details from db
      let productDetails = whintegration.config.obj.products.find(x => x.wrd_id == product.id);
      if (productDetails) {
        rows = rows + `<tr>
                         <td data-label="Aantal">
                           <input class="order__numinput"
                                  type="number"
                                  min="0"
                                  max="999"
                                  maxlength="3"
                                  data-product-id="${productDetails.wrd_id}"
                                  data-product-title="${productDetails.title}"
                                  data-product-price="${productDetails.price}"
                                  data-product-price-cents="${productDetails.price_cents}"
                                  data-product-deposit="${productDetails.deposit_money}"
                                  data-product-deposit-cents="${productDetails.deposit_money_cents*100}"
                                  data-product-unit="${productDetails.unit}"
                                  value="${product.num}"
                                  />
                         </td>
                         <td data-label="Eenheid">${productDetails.unit}</td>
                         <td data-label="Product">
                           <!-- <a href="${whintegration.config.obj.productslink}#${productDetails.wrd_id}">${productDetails.title}</a> -->
                           ${productDetails.title}
                         </td>
                         <td data-label="Producent">${productDetails.producer}</td>
                         <td data-label="Prijs" style="white-space: nowrap;">€ ${util.formatMoney(productDetails.price)}</td>
                         <td data-label="Statiegeld" style="white-space: nowrap;">€ ${util.formatMoney(productDetails.deposit_money)}</td>
                         <td data-label="Opmerkingen">${productDetails.remarks}</td>
                       </tr>`;

        totalPrice += (productDetails.price * product.num) + (productDetails.deposit_money * product.num);

        product.price = productDetails.price;
        product.deposit_money = productDetails.deposit_money;
      } else {
        console.error("could not find product #" + product.id);
      }
    }

    $('#productstable tbody').append(rows);

    $numInputs = $('.order__numinput');
    $numInputs.bind('input', onNumInputChange);
    updateTotalPrice();

    $('#productstable').DataTable({
      "paging": false,
      "ordering": false,
      "info": false,
      "searching": false,
      'language': {
        'emptyTable': 'Geen producten geselecteerd'
      },
      "scrollX": true,
    });

    $('.js-orderform').addClass('show');
  } catch (err) {
    console.error(err);
    $('.order__noitems').addClass('show');
  }
}

function getProductsFromInputs() {
  let products = [];
  $numInputs.each(function() {
    products.push({
      id: parseInt($(this).data('product-id')),
      num: !isNaN(parseInt($(this).val())) ? parseInt($(this).val()) : 0,
      unit: $(this).data('product-unit'),
    })
  });

  return products;
}

async function updateTotalPrice() {
  let totalPrice = 0.0;

  $numInputs.each(function() {
    let price = $(this).data('product-price');
    let num = parseInt($(this).val());
    if (num > 0) {
      let deposit_money = $(this).data('product-deposit');
      totalPrice += (price * num) + (deposit_money * num);
    }
  });

  let discount = await rpc.GetDiscount(getProductsFromInputs());

  $('.js-discount-price').text('€ ' + util.formatMoney(discount));
  $('.js-total-price').text('€ ' + (util.formatMoney(totalPrice - discount)));
}

async function onNumInputChange() {
  console.log($(this));
  if ($(this).val() === '0')
    $(this).val('');

  await util.setLocalStorageItemsByInputs($numInputs);

  updateTotalPrice();
}
